
import { defineComponent } from "vue";
import PlanTransportGroupResultJournal from "@/components/journals/PlanTransportGroupResultJournal/PlanTransportGroupResultJournal.vue";
import PlanCargoResultJournal from "@/components/journals/PlanCargoResultJournal/PlanCargoResultJournal.vue";
import DxButton from "devextreme-vue/button";
import router from "@/router";
import api from "@/services/api";
import { PlanState } from "./enums";
import store from "@/store";

export default defineComponent({
  components: {
    PlanTransportGroupResultJournal,
    PlanCargoResultJournal,
    DxButton,
  },
  props: {
    parentModel: { required: true },
    isCalculation: { required: true },
  },
  computed: {
    isPlanCompleted() {
      return this.parentModel.state === PlanState.Completed;
    },
    isPlanNotFound() {
      return this.parentModel.state === PlanState.NotFound;
    },
    time() {
      if (this.seconds == 0) return "0 сек";
      else return this.getTime(this.seconds);
    },
  },
  methods: {
    startTimer() {
      this.interval = setInterval(this.incrementTime, 1000);
    },
    incrementTime() {
      this.seconds += 1;
    },
    changeView(isRollupView: any) {
      this.isRollupView = isRollupView;
    },
    onButtonClick() {
      if (this.loading) this.onStop();
      else this.onGenerateReport();
    },
    onStop() {
      this.stopping = true;
      this.loading = false;
      clearInterval(this.interval);
      this.seconds = 0;
    },
    onGenerateReport() {
      router.push({
        name: "loadingReport",
        params: {
          planId: `${this.parentModel.id}`,
          planDescription: `${this.parentModel.name}`,
        },
      });
    },
    getTime(seconds: any) {
      var hr = Math.floor(seconds / 3600);
      var min = Math.floor(seconds / 60) - hr * 60;
      var sec = seconds % 60;

      return (
        (hr != 0 ? hr + " час " : "") +
        (min != 0 ? min + " мин " : "") +
        (sec != 0 ? sec + " сек" : "")
      );
    },
  },
  data() {
    return {
      seconds: 0,
      isRollupView: false,
      stopping: false,
      loading: false,
      timeMessage: "",
    };
  },
});
